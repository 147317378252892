<template>
  <div class="adsItem">
    <ins class="adsbygoogle" v-bind="getAdConfig()" />
    <div v-if="showDebug" class="ad-msg">
      {{ adType }}_{{ adPosition }}= {{ getAdConfig() }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    adPosition: {
      type: Number,
      required: true
    },
    adType: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      adsense: "adsenseConfig",
      showDebug: "showDebug"
    })
  },
  methods: {
    getAdConfig () {
      console.log(`${this.adType}_${this.adPosition}`);
      return this.adsense[`${this.adType}_${this.adPosition}`] || {};
    },
  },
}
</script>